import styled from 'styled-components'
import LeftBar from '../../home/components/leftBar'
import StepperBody from './body'
import HistoryProvider from '../../../contexts/history'
import ReactGA from 'react-ga4'

const DIV = styled.div`
    display: flex;
    height: 100vh;
    overflow: hidden !important;
`

const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    scroll-behavior: smooth;
    overflow: auto;
    flex: 1;
`

export default function GeneratorWaitingPage() {
    ReactGA.send({
        hitType: 'pageview',
        page: '/espera',
        title: 'GeneratorWaitingPage',
    })
    return (
        <DIV>
            <HistoryProvider>
                <LeftBar></LeftBar>
                <Page>
                    <StepperBody />
                </Page>
            </HistoryProvider>
        </DIV>
    )
}
