import styled from 'styled-components'
import LeftBar from '../../home/components/leftBar'
import HistoryProvider from '../../../contexts/history'
import Projetos from './body'
import ReactGA from 'react-ga4'

const Page = styled.div`
    display: flex;
    height: 100vh;
    overflow: hidden !important;
`

export default function ProjetosPage() {
    ReactGA.send({
        hitType: 'pageview',
        page: '/projetos',
        title: 'Projects',
    })

    return (
        <Page>
            <HistoryProvider>
                <LeftBar />
                <Projetos />
            </HistoryProvider>
        </Page>
    )
}
