import styled from 'styled-components'
import logoTransparente from '../../assets/licito-logo.svg'
import {COLORS} from '../../utils/colors'
import Footer from '../../globalComponents/footer'
import {useEffect, useMemo, useState} from 'react'
import {useLoading} from '../../globalComponents/useLoading'
import {AuthApi} from '../../services/AuthApi.service'
import useError from '../../globalComponents/useError'
import {CookiesService} from '../../services/Cookie.service'

const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    background-color: #ffffff;
    min-height: 100vh;
    gap: 30px;
    position: relative;
`
const Logo = styled.img`
    width: 180px;
`
export default function ValidateEmailPage() {
    const {Spinner, isLoading, setIsLoading} = useLoading()
    const {ErrorComponent, showError} = useError()
    const [message, setMessage] = useState('Validando email...')

    const search = useMemo(() => {
        return window.location.search
    }, [window.location.search])
    const token = useMemo(() => {
        return search.slice(7, search.length)
    }, [search])

    async function confirmEmail() {
        if (!token) return
        try {
            setIsLoading(true)
            const auth_api = new AuthApi()
            await auth_api.confirmEmail(token)

            setTimeout(() => {
                window.location.href = '/'
            }, 2000)
        } catch (error: any) {
            // showError(error)
            console.log(error)
            setMessage('')
            setTimeout(() => {
                window.location.href = '/login'
            }, 2000)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (!token) return
        confirmEmail()
    }, [token])

    return (
        <Page>
            <Logo src={logoTransparente} />
            <div>
                <h2>{message}</h2>
            </div>
            {isLoading && <Spinner />}
            {ErrorComponent}
            <Footer />
        </Page>
    )
}
