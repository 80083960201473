import styled from 'styled-components'
import {
    KeyboardEvent,
    useContext,
    useEffect,
    useRef,
    useState,
    useMemo,
} from 'react'
import {AssistantApi} from '../../../../../services/AssistantApi.service'
import {HistoryContext} from '../../../../../contexts/history'
import {useLoading} from '../../../../../globalComponents/useLoading'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import ImageIcon from '@mui/icons-material/Image'
import FolderCopyIcon from '@mui/icons-material/FolderCopy'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import LanguageIcon from '@mui/icons-material/Language'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ChatCounter from './charCounter'

interface MotorButtonProps {
    isOpen: boolean
}

interface DropdownMenuProps {
    isOpen: boolean
}

interface MenuItemProps {
    isSelected: boolean
}

const InputWrapper = styled.div`
    width: 800px;
    max-width: 100%;
    background: white;
    margin: 0 auto 32px;
    border-radius: 15px;
    margin-bottom: 50px;
`

const InputContainer = styled.div`
    position: relative;
    padding: 12px;
`

const InputRow = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
    position: relative;
    justify-content: space-between;
`

const TextareaContainer = styled.div`
    flex: 1;
    min-width: 0;
    max-height: 230px;
    margin-right: 8px;
    overflow-y: auto;
`

const StyledTextarea = styled(TextareaAutosize)`
    width: 100%;
    min-height: 40px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px;
    color: #000;
    background: transparent;
    border: none;
    resize: none;
    outline: none;
    margin-bottom: 4px;

    &::placeholder {
        color: #000000;
    }
`

const MotorButton = styled.button<MotorButtonProps>`
    display: flex;
    align-items: center;
    gap: 4px;
    background: #f3f4f6;
    padding: 6px 10px;
    border-radius: 20px;
    font-size: 13px;
    color: #4b5563;
    border: none;
    cursor: pointer;
    transition: all 0.2s;
    min-width: 180px;
    height: 32px;
    white-space: nowrap;
    justify-content: space-between;
    flex-shrink: 0;

    &:hover {
        background: #e5e7eb;
    }

    .button-content {
        display: flex;
        align-items: center;
        gap: 6px;
    }

    .arrow-icon {
        font-size: 16px;
        transition: transform 0.2s;
        transform: ${({isOpen}) => (isOpen ? 'rotate(180deg)' : 'rotate(0)')};
    }
`

const DropdownMenu = styled.div<DropdownMenuProps>`
    position: absolute;
    top: 38px;
    right: 0;
    background: white;
    border-radius: 12px;
    border: 1px solid #e5e7eb;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
    display: ${({isOpen}) => (isOpen ? 'block' : 'none')};
    z-index: 10;
    min-width: 180px;
`

const MenuItem = styled.button<MenuItemProps>`
    width: 100%;
    padding: 8px 16px;
    text-align: left;
    background: ${({isSelected}) => (isSelected ? '#f3f4f6' : 'transparent')};
    border: none;
    cursor: pointer;
    font-size: 14px;
    color: #4b5563;
    display: flex;
    align-items: center;
    gap: 8px;

    &:hover {
        background: #f3f4f6;
    }

    &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
`

const BottomRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;
    padding-left: 8px;
`

const LeftButtons = styled.div`
    display: flex;
    gap: 16px;
`

const IconButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    padding: 4px;
    cursor: pointer;
    color: #6b7280;
    transition: color 0.2s;

    &:hover {
        background: transparent;
        color: #374151;
    }

    svg {
        width: 20px;
        height: 20px;
    }
`

const SendButtonContainer = styled.div`
    position: relative;
    display: inline-block;
`

const SendButton = styled.button`
    width: 40px;
    background: #012441;
    color: white;
    border: none;
    padding: 6px 12px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background: rgb(3, 71, 128);
    }

    &:disabled {
        background: #9ca3af;
        cursor: not-allowed;
    }
`

const StyledChatCounter = styled.div`
    position: absolute;
    top: 20%;
    left: 170%;
    transform: translateX(-50%);
    margin-top: 4px;
    color: #9ca3af;

    .counter {
        font-size: 10px;
        color: #9ca3af;
        white-space: nowrap;
    }

    span {
        color: #9ca3af !important;
    }
`

const ButtonTitle = styled.div`
    @media (max-width: 455px) {
        text-align: left;
        font-size: 10px;
        white-space: normal;
        overflow-wrap: break-word;
        word-break: break-word;
        line-height: 1.2;
        max-width: 120px;
    }
`

export default function Input({suggestion = ''}: {suggestion?: string}) {
    const inputRef = useRef<HTMLTextAreaElement | null>(null)
    const [newMessage, setNewMessage] = useState(suggestion)
    const [maxLength, setMaxLength] = useState<number>(1500)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const {Spinner, setIsLoading, isLoading} = useLoading()
    const {
        selected,
        includeMessageAtArray,
        messagesArray,
        assistant,
        getLimits,
        setAssistant,
        assistants,
    } = useContext(HistoryContext)
    const api = useMemo(() => new AssistantApi(), [])
    const assistantApi = useMemo(() => new AssistantApi(), [])

    useEffect(() => {
        if (suggestion) {
            setNewMessage(suggestion)
        }
    }, [suggestion])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                isDropdownOpen &&
                !(event.target as Element).closest('.motor-dropdown')
            ) {
                setIsDropdownOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () =>
            document.removeEventListener('mousedown', handleClickOutside)
    }, [isDropdownOpen])

    useEffect(() => {
        async function fetchConstraints() {
            try {
                const maxLengthFromApi = await assistantApi.getConstraints()
                setMaxLength(maxLengthFromApi.max_characters)
            } catch (error) {
                console.error('Erro ao obter constraints:', error)
            }
        }
        fetchConstraints()
    }, [assistantApi])

    const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault()
            sendMessage()
        }
    }

    const handleMotorChange = (selectedAssistant: any) => {
        setAssistant(selectedAssistant)
        setIsDropdownOpen(false)
    }

    async function sendMessage() {
        setIsLoading(true)
        const thread_id = selected?.guid
        if (!newMessage || !thread_id || !assistant) {
            setIsLoading(false)
            return
        }
        includeMessageAtArray({
            guid: '',
            assistant_id: '',
            created_at: new Date(),
            thread_id: thread_id,
            author_id: 'user',
            content: newMessage,
        })
        setNewMessage('')
        await api
            .sendMessage(
                thread_id,
                newMessage,
                assistant?.guid,
                selected.owner_id
            )
            .then((message) => {
                includeMessageAtArray(message)
                getLimits()
            })
            .catch((error) =>
                includeMessageAtArray({
                    content: 'Erro ao enviar mensagem. Tente novamente.',
                    thread_id: '',
                    assistant_id: 'schief',
                    author_id: '',
                    created_at: new Date(),
                    guid: '',
                })
            )
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <InputWrapper>
            <InputContainer>
                <Spinner />
                <InputRow>
                    <div
                        className="motor-dropdown"
                        style={{position: 'relative', marginLeft: 'auto'}}
                    >
                        <MotorButton
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                            isOpen={isDropdownOpen}
                        >
                            <div className="button-content">
                                <LanguageIcon style={{fontSize: '18px'}} />
                                <ButtonTitle>
                                    {assistant?.name ||
                                        'Selecione um assistente'}
                                </ButtonTitle>
                            </div>
                            <KeyboardArrowDownIcon className="arrow-icon" />
                        </MotorButton>

                        <DropdownMenu isOpen={isDropdownOpen}>
                            {assistants?.map((ast) => (
                                <MenuItem
                                    key={ast.guid}
                                    isSelected={ast.guid === assistant?.guid}
                                    onClick={() => handleMotorChange(ast)}
                                >
                                    <LanguageIcon style={{fontSize: '18px'}} />
                                    {ast.name}
                                </MenuItem>
                            ))}
                        </DropdownMenu>
                    </div>
                </InputRow>
                <InputRow>
                    <TextareaContainer>
                        <StyledTextarea
                            ref={inputRef}
                            maxLength={maxLength}
                            disabled={isLoading}
                            placeholder="Envie uma mensagem para o Licito.guru"
                            value={newMessage}
                            onChange={(event) =>
                                setNewMessage(event.target.value)
                            }
                            onKeyDown={handleKeyDown}
                        />
                    </TextareaContainer>
                </InputRow>

                <BottomRow>
                    <div>
                        {/* <LeftButtons>
                        <IconButton>
                            <FolderCopyIcon />
                        </IconButton>
                        <IconButton>
                            <ImageIcon />
                        </IconButton>
                    </LeftButtons> */}
                    </div>

                    <SendButtonContainer>
                        <SendButton disabled={isLoading} onClick={sendMessage}>
                            <ArrowForwardIcon />
                        </SendButton>
                        <StyledChatCounter>
                            <ChatCounter
                                text={newMessage}
                                maxLength={maxLength}
                            />
                        </StyledChatCounter>
                    </SendButtonContainer>
                </BottomRow>
            </InputContainer>
        </InputWrapper>
    )
}
