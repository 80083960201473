import styled from 'styled-components'
import LeftBar from '../../home/components/leftBar'
import HistoryProvider from '../../../contexts/history'
import DocumentGenerator from './body'
import ReactGA from 'react-ga4'

const DIV = styled.div`
    display: flex;
    height: 100vh;
    overflow: hidden !important;
`

export default function ProjetosPage() {
    ReactGA.send({
        hitType: 'pageview',
        page: '/gerador',
        title: 'Generator',
    })
    return (
        <DIV>
            <HistoryProvider>
                <LeftBar />
                <DocumentGenerator />
            </HistoryProvider>
        </DIV>
    )
}
