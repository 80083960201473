import axios, {AxiosInstance} from 'axios'
import MyAxiosInstanceDocument from './axiosInstanceDocument'
import {Question} from '../types/question'
import {project} from '../types/project'

export class DocumentApi {
    private documentApi: AxiosInstance

    client = axios.create({
        baseURL: process.env.REACT_APP_DOCUMENT_URL,
    })

    constructor() {
        this.documentApi = MyAxiosInstanceDocument
    }

    async getResume(project_id: string): Promise<any> {
        try {
            const res = await this.documentApi.get(
                `/projects/${project_id}/answers`
            )
            return res.data
        } catch (error) {
            throw error
        }
    }

    async postAnswer(answer: any): Promise<Question> {
        try {
            const res = await this.documentApi.post('/answers', answer)
            return res.data
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async getPreviousQuestion(
        project_id: string,
        question_id: string
    ): Promise<Question> {
        try {
            const res = await this.documentApi.get(
                `/projects/${project_id}/previous-question/${question_id}`
            )

            return res.data
        } catch (error: any) {
            throw error
        }
    }

    async getNextQuestion(project_id: string): Promise<Question> {
        try {
            const res = await this.documentApi.get(
                `/projects/${project_id}/next-question`
            )

            return res.data
        } catch (error: any) {
            throw error
        }
    }

    async finishProject(project_id: string): Promise<void> {
        try {
            await this.documentApi.post(`/projects/${project_id}/finish`)
            return
        } catch (error) {
            throw error
        }
    }

    async getProjects(): Promise<project[]> {
        try {
            const res = await this.documentApi.get('/projects')
            return res.data
        } catch (error) {
            console.error(error)
            throw new Error('Erro ao obter documentos da API.')
        }
    }

    async postProject(projectData: {
        created_at: string
        name: string
        description: string
        updated_at: string
        start_date: string
        end_date: string
        is_active: boolean
        project_type_id: string
        status: string
    }): Promise<Question> {
        try {
            const response = await this.documentApi.post(
                '/projects',
                projectData
            )
            return response.data
        } catch (error) {
            console.error('Erro criando projeto:', error)
            if (axios.isAxiosError(error) && error.response) {
                throw new Error(
                    `Erro ao criar projeto: ${
                        error.response.data.message || error.response.statusText
                    }`
                )
            } else {
                throw new Error('Erro ao criar projeto: Erro desconhecido')
            }
        }
    }

    async archiveProject(project_guid: string): Promise<void> {
        try {
            await this.documentApi.delete(`/projects/${project_guid}/archive`)
            return
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async unarchiveProject(project_guid: string): Promise<void> {
        try {
            await this.documentApi.put(`/projects/${project_guid}/restore`)
            return
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async getArchivedProjects(): Promise<project[]> {
        try {
            const res = await this.documentApi.get('/projects/archived')
            return res.data
        } catch (error) {
            console.log(error)
            throw error
        }
    }
}
