import styled from 'styled-components'
import logo from '../../../../assets/licito-logo.svg'
import History from './history/history'
import {useContext, useState, useRef, useEffect} from 'react'
import HideShowBarButton from '../../../../globalComponents/hideShowBarButton'
import Button from '../../../../globalComponents/ButtonPattern'
import {AuthContext} from '../../../../contexts/auth'
import NavList from './navList'
import {HistoryContext} from '../../../../contexts/history'
import {List} from '@mui/material'
import {useLocation, useNavigate} from 'react-router-dom'
import chatsIcon from '../../../../assets/chats.svg'
import documentsIcon from '../../../../assets/documents.svg'
import exitIcon from '../../../../assets/exit.svg'
import EditNoteIcon from '@mui/icons-material/EditNote'
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'

const Section = styled.section<{show: boolean}>`
    width: ${({show}) => (show ? '300px' : '0px')};
    min-width: ${({show}) => (show ? '300px' : '0px')};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0px 44px 0;
    justify-content: space-between;
    position: relative;
    transform: translateX(${({show}) => (show ? '0' : '-100%')});
    transition: transform 0.3s ease-in-out;
    z-index: 55;
    border-right: 1px solid #e0e0e0;
    @media (max-width: 640px) {
        position: absolute;
        min-height: 100vh;
    }
    overflow-y: auto;
    background-color: #ffffff;
`

const Logo = styled.img`
    width: 200px;
`

const ListWrapper = styled.div`
    width: 100%;
    display: flex;
    position: relative;
    align-items: flex-start;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 20px;
`

const ButtonsContainer = styled.div`
    flex-direction: column;
    width: 100%;
    gap: 8px;
    align-items: flex-start;
`
const CreditsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 252px;
    height: 64px;
    gap: 8px;
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
    border: 1px solid #e4e4e4;
    border-radius: 8px;
`
const CreditsContainerTop = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    margin-left: 16px;
    margin-top: 16px;
    width: 100%;
`
const CreditsContainerBottom = styled.div<{pathname: string}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 220px;
    height: 36px;
    border: 1px solid
        ${({pathname}) => {
            return pathname === '/' ? '#3acdb4' : '#3394EF'
        }};
    border-radius: 4px;
    margin-left: 16px;
    color: ${({pathname}) => {
        return pathname === '/' ? '#3acdb4' : '#3394EF'
    }};
    cursor: pointer;
`

const Credits = styled.div<{pathname: string}>`
    display: flex;
    flex-direction: column;
    min-width: 63px;
    padding: 5px;
    height: 23px;
    border-radius: 4px;
    background-color: ${({pathname}) => {
        return pathname === '/' ? '#3acdb4' : '#3394EF'
    }};
    color: #ffffff;
    align-items: center;
    justify-content: center;
    font-size: 12px;
`

interface Props {
    visibility: string
}

const Alert = styled.div<Props>`
    color: ${(props) => props.visibility};
    transition: color 0.25s;
`

export default function LeftBar() {
    const [show, setShow] = useState(() => {
        if (typeof window !== 'undefined') {
            return window.innerWidth > 640
        }
        return true
    })
    const {userInfo, logout} = useContext(AuthContext)
    const {createNewChat, limits, alertVisibility, isChat, setIsChat} =
        useContext(HistoryContext)
    const [openList, setOpenList] = useState(false)
    const listRef = useRef<HTMLDivElement | null>(null)
    const [selectedButton, setSelectedButton] = useState<
        'chats' | 'documentos' | null
    >(() => {
        return window.location.pathname === '/projetos' ? 'documentos' : 'chats'
    })
    const navigate = useNavigate()
    const handleChatsClick = () => {
        setSelectedButton('chats')
        setIsChat(true)
        createNewChat()
        navigate('/')
    }
    const handleDocumentosClick = () => {
        setSelectedButton('documentos')
        setIsChat(false)
        navigate('/projetos')
    }
    const createInitialsSVG = (firstName: string, lastName?: string) => {
        const initials =
            (firstName?.[0] || '').toUpperCase() +
            (lastName?.[0] || '').toUpperCase()
        const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="30" height="30">
            <circle cx="20" cy="20" r="20" fill="#012441"/>
            <text x="20" y="25" text-anchor="middle" fill="white" font-size="16" font-family="Arial">${initials}</text>
            </svg>`
        return `data:image/svg+xml;base64,${btoa(svg)}`
    }
    const location = useLocation()

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                listRef.current &&
                !listRef.current.contains(event.target as Node)
            ) {
                setOpenList(false)
            }
        }
        if (openList) {
            document.addEventListener('mousedown', handleClickOutside)
        } else {
            document.removeEventListener('mousedown', handleClickOutside)
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [openList])

    return (
        <>
            <Section show={show}>
                <Wrapper>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            paddingLeft: '10px',
                        }}
                    >
                        <MenuOpenIcon
                            fontSize="large"
                            titleAccess="Fechar menu"
                            style={{
                                left: '5%',
                                top: '10px',
                                fontSize: '30px',
                                position: 'absolute',
                                cursor: 'pointer',
                            }}
                            onClick={() => setShow((prev) => !prev)}
                        />
                        <Logo src={logo} alt="Logo Schief.ai" />
                    </div>

                    <Button
                        onclick={handleDocumentosClick}
                        IconAltText="Criar Documento"
                        text="Documentos"
                        SVGIcon={documentsIcon}
                        align="left"
                        customStyle={{
                            border: 'none',
                            borderRadius: '0',
                            justifyContent: 'flex-start',
                            width: '100%',
                            paddingLeft: '20px',
                        }}
                        customIconStyle={{
                            width: '30px',
                            height: '30px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        selectedButton={
                            selectedButton === 'documentos' ||
                            location.pathname !== '/'
                        }
                    />
                    <Button
                        onclick={handleChatsClick}
                        IconAltText="Criar novo chat"
                        text="Consultas"
                        SVGIcon={chatsIcon}
                        align="left"
                        customStyle={{
                            border: 'none',
                            borderRadius: '0',
                            justifyContent: 'flex-start',
                            width: '100%',
                            paddingLeft: '20px',
                        }}
                        customIconStyle={{
                            width: '30px',
                            height: '30px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                        selectedButton={
                            selectedButton === 'chats' &&
                            location.pathname === '/'
                        }
                        tooltip="Criar nova consulta"
                    />
                    {/* {alertVisibility === 'red' && (
                        <Alert visibility={alertVisibility}>
                            Você já está em um novo chat!
                        </Alert>
                    )} */}
                    {isChat && window.location.pathname == '/' ? (
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <History />
                            {window.location.pathname === '/' && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        left: '245px',
                                        top: '262px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <AddCommentOutlinedIcon
                                        fontSize="medium"
                                        onClick={() => handleChatsClick()}
                                        titleAccess="Criar nova consulta"
                                        style={{color: '#3acdb4'}}
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        <List
                            style={{width: '90%', marginBottom: '300px'}}
                        ></List>
                    )}
                </Wrapper>

                <ListWrapper ref={listRef}>
                    {openList && <NavList />}
                    <ButtonsContainer>
                        <CreditsContainer>
                            <CreditsContainerTop>
                                <Credits pathname={window.location.pathname}>
                                    {window.location.pathname === '/'
                                        ? 'CHAT'
                                        : 'DOCUMENTOS'}
                                </Credits>
                                <span
                                    style={{color: '#ABABAB', fontSize: '14px'}}
                                >
                                    {window.location.pathname === '/'
                                        ? `${limits.usages.messages.current}/${limits.usages.messages.max} `
                                        : `${limits.usages.projects.current}/${limits.usages.projects.max} `}
                                    Créditos
                                </span>
                            </CreditsContainerTop>
                            {/* <CreditsContainerBottom
                                onClick={() => {
                                    navigate('/planos')
                                }}
                                pathname={window.location.pathname}
                            >
                                <ArrowCircleUpIcon />
                                <span>Upgrade</span>
                            </CreditsContainerBottom> */}
                        </CreditsContainer>
                        <Button
                            onclick={() => setOpenList((prev) => !prev)}
                            IconAltText="Abrir Lista de Configurações"
                            text={`${userInfo.first_name}`}
                            SVGIcon={createInitialsSVG(
                                userInfo.first_name,
                                userInfo.last_name
                            )}
                            align="left"
                            // endText={`${limits.current_answers}/${limits.max_answers}`}
                            tooltip="Conta"
                            customStyle={{
                                border: 'none',
                                padding: '8px 16px',
                                background: 'transparent',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px',
                                width: '90%',
                                justifyContent: 'flex-start',
                                paddingLeft: '20px',
                            }}
                        />
                        <Button
                            onclick={logout}
                            IconAltText="sair"
                            text="Sair"
                            SVGIcon={exitIcon}
                            align="left"
                            customStyle={{
                                border: 'none',
                                padding: '8px 16px',
                                background: 'transparent',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px',
                                width: '90%',
                                justifyContent: 'flex-start',
                                paddingLeft: '20px',
                            }}
                        />
                    </ButtonsContainer>
                </ListWrapper>
            </Section>
            <HideShowBarButton
                position="left"
                offset={show ? '300px' : '0px'} // Ajuste a posição com base no estado
                show={show}
                setShow={setShow}
            />
        </>
    )
}
