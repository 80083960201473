import React, {useContext, useState, useEffect, useMemo} from 'react'
import styled from 'styled-components'
import {
    Grid2,
    CardContent,
    Typography,
    Modal,
    Box,
    Button,
    Select,
    MenuItem,
    TextField,
    Tooltip,
    Menu,
    Fade,
    Table,
    TableContainer,
    TableHead,
    Divider,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    Skeleton,
} from '@mui/material'
import {useNavigate} from 'react-router-dom'
import {HistoryContext} from '../../../contexts/history'
import ProjetoVazio from '../../../assets/projeto-vazio.svg'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import EditIcon from '@mui/icons-material/Edit'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import {DocumentApi} from '../../../services/DocumentApi.service'
import {project} from '../../../types/project'
import Alert from '@mui/material/Alert'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'
import CloseIcon from '@mui/icons-material/Close'
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'

const Page = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: start;
    position: relative;
    scroll-behavior: smooth;
    flex: 1;
`

const Container = styled.div`
    max-height: calc(100vh - 100px);
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    margin-left: 100px;
    overflow-y: auto;
    box-sizing: border-box;
`

const CardContainer = styled.div`
    width: 221px;
    height: 300px;
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
`

const CardHead = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 16px;
    margin-left: 20px;
    color: #000f35;
`

const CardBody = styled(CardContent)`
    height: 200px;
    display: flex !important;
    flex-direction: column !important;
    cursor: pointer;
    align-items: flex-start;
    overflow: hidden;
    margin-left: 4px;
`

const TruncatedTypography = styled(Typography)`
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    max-height: 60px;
    word-break: break-word;
`

const CardFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    margin-left: 20px;
    height: 50px;
`

const SelectedSchiefMessage = styled.span`
    font-size: 38px;
    text-align: start;
    top: 48px;
    margin-left: 110px;
`

const ModalContent = styled(Box)`
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 320px;
    min-height: 232px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
`

const UnarchiveModalContent = styled.div`
    background-color: white;
    border-radius: 8px;
    width: 100%;
    max-width: 80%;
    max-height: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 24px;
    outline: none;
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
        /* Para tablets e maiores */
        width: 60%;
    }

    @media (min-width: 1024px) {
        /* Para telas de desktop */
        width: 40%;
    }
`

const Header = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-left: 16px;
    margin-bottom: 16px;
    margin-top: 16px;
    font-size: 18px;
    color: #707070;
`

const HeadModal = styled.div`
    display: flex;
    padding: 20px;
    justify-content: space-between;
`

const BodyModal = styled.div`
    aling-content: center;
    display: flex;
    padding: 20px;
    overflow-y: auto;
`
const ContentStatus = styled.div`
    color: white;
    background-color: #ffa500;
    padding: 6px;
    border-radius: 100px;
    font-size: 14px;
    text-align: center;
`

const StyledIconButton = styled(IconButton)``

export default function Projetos() {
    const {
        projectArray,
        getNextQuestion,
        postProject,
        setQuestionArray,
        questionArray,
        limits,
        archiveProject,
        getProjects,
        unarchieveModal,
        archivedProjects,
        unarchiveProject,
        setUnarchieveModal,
    } = useContext(HistoryContext)
    const [isModalOpen, setModalOpen] = useState(false)
    const [selectedDocumentType, setSelectedDocumentType] = useState('ETP')
    const [documentName, setDocumentName] = useState('')
    const [projects, setProjects] = useState<project[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate()
    const api = useMemo(() => new DocumentApi(), [])
    const [openAlertErro, setOpenAlertErro] = React.useState(false)
    const [selectedProject, setSelectedProject] = useState('')

    useEffect(() => {
        const fetchProjects = async () => {
            setIsLoading(true)
            await getProjects()
            setIsLoading(false)
        }
        fetchProjects()
    }, [])

    useEffect(() => {
        // console.log('questionArray atualizado', questionArray)
        if (questionArray.length > 0) {
            navigate('/gerador')
        }
    }, [questionArray])

    const handleOpenModal = () => {
        if (limits.usages.projects.current < limits.usages.projects.max) {
            setModalOpen(true)
            return
        }
        setOpenAlertErro(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
        setDocumentName('')
        setSelectedDocumentType('')
    }

    const handleProcessing = async (
        project_id: string,
        project_name: string
    ) => {
        console.log('Processing clicked for project:', project_id)
        localStorage.setItem('projectId', JSON.stringify(project_id))
        localStorage.setItem('projectName', JSON.stringify(project_name))
        navigate('/espera')
    }

    const handleEdit = async (project_id: string, project_name: string) => {
        console.log('Edit clicked for project:', project_id)
        const res = await api.getNextQuestion(project_id)
        localStorage.setItem('questionArray', JSON.stringify([res]))
        localStorage.setItem('projectId', JSON.stringify(project_id))
        localStorage.setItem('projectName', JSON.stringify(project_name))

        setQuestionArray([res])
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (
        event: React.MouseEvent<HTMLElement>,
        project: string
    ) => {
        setAnchorEl(event.currentTarget)
        setSelectedProject(project)
    }
    const handleClose = () => {
        setUnarchieveModal(false)
        setAnchorEl(null)
    }
    const formatDate = (dateString: string) => {
        const date = new Date(dateString)

        const day = String(date.getDate()).padStart(2, '0')
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const year = date.getFullYear()
        const hours = String(date.getHours()).padStart(2, '0')
        const minutes = String(date.getMinutes()).padStart(2, '0')

        return `${day}/${month}/${year} ${hours}:${minutes}`
    }
    const handleConfirm = async () => {
        try {
            const projectData = {
                created_at: new Date().toISOString(),
                name: documentName,
                description: `${documentName} - ${selectedDocumentType}`,
                updated_at: new Date().toISOString(),
                start_date: new Date().toISOString(),
                end_date: new Date().toISOString(),
                is_active: true,
                project_type_id: '899704fd-10be-4aac-8b36-cafccc3f0f0a',
                status: 'INCOMPLETE',
            }

            const res = await api.postProject(projectData)
            console.log('handle', res)
            localStorage.setItem('questionArray', JSON.stringify([res]))
            localStorage.setItem('projectName', JSON.stringify(documentName))
            localStorage.setItem('projectId', JSON.stringify(res.project_id))
            setQuestionArray([res])

            handleCloseModal()
        } catch (error) {
            console.error('Error creating project:', error)
        }
    }

    const handleDownload = (project: project) => {
        if (project.status === 'INCOMPLETE') {
            handleEdit(project.id, project.name)
        } else if (
            project.status === 'GENERATING_DOCS' ||
            project.status === 'PROCESSING'
        ) {
            handleProcessing(project.id, project.name)
        } else if (project.status === 'FINISHED' && project.doc_url) {
            window.open(project.doc_url, '_blank')
        } else {
            console.log('Download clicked for project:', project.id)
        }
    }

    const archiveProject_ = async (project_id: string) => {
        archiveProject(selectedProject)
        console.log('archiveProject', project_id)
    }

    const _desarquivarModal = () => {
        return (
            <Modal
                onClose={handleClose}
                open={unarchieveModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disablePortal
                disableEnforceFocus
            >
                <UnarchiveModalContent>
                    <HeadModal>
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                        >
                            Documentos arquivados
                        </Typography>
                        <StyledIconButton onClick={handleClose}>
                            <CloseIcon />
                        </StyledIconButton>
                    </HeadModal>
                    <Divider orientation="horizontal" flexItem />
                    <BodyModal>
                        <TableContainer component={Box}>
                            <Table
                                sx={{minWidth: 200}}
                                aria-label="simple table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nome</TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {archivedProjects?.map((item) => (
                                        <TableRow
                                            key={item.id}
                                            sx={{
                                                '&:last-child td, &:last-child th':
                                                    {border: 0},
                                            }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <ArticleOutlinedIcon
                                                    sx={{marginRight: '10px'}}
                                                />
                                                {item.name}
                                            </TableCell>
                                            <TableCell align="right">
                                                <IconButton
                                                    onClick={() =>
                                                        unarchiveProject(
                                                            item.id
                                                        )
                                                    }
                                                >
                                                    <UnarchiveOutlinedIcon />
                                                </IconButton>
                                                {
                                                    // <IconButton>
                                                    //     <DeleteOutlinedIcon
                                                    //         onClick={() =>
                                                    //             deleteThread(
                                                    //                 item.guid
                                                    //             )
                                                    //         }
                                                    //     />
                                                    // </IconButton>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </BodyModal>
                </UnarchiveModalContent>
            </Modal>
        )
    }

    return (
        <Page>
            <SelectedSchiefMessage>Meus documentos</SelectedSchiefMessage>

            {isLoading ? (
                <Container style={{marginTop: '25px'}}>
                    <Grid2 container rowSpacing={2} columnSpacing={3}>
                        {[...Array(10)].map((_, index) => (
                            <Grid2 key={index} size="auto">
                                <CardContainer>
                                    <Skeleton
                                        variant="rectangular"
                                        width={220}
                                        height={118}
                                    />
                                    <Skeleton
                                        variant="text"
                                        sx={{fontSize: '1rem', marginTop: 1}}
                                    />
                                    <Skeleton
                                        variant="text"
                                        sx={{fontSize: '0.8rem'}}
                                    />
                                </CardContainer>
                            </Grid2>
                        ))}
                    </Grid2>
                </Container>
            ) : projectArray.length === 0 ? (
                <div
                    style={{
                        textAlign: 'center',
                        margin: '0 auto',
                        height: '80%',
                        overflowY: 'auto',
                        maxWidth: '90%',
                        marginTop: '10%',
                    }}
                >
                    <img
                        src={ProjetoVazio}
                        alt="Projeto Vazio"
                        style={{maxWidth: '100%', height: 'auto'}}
                    />
                    <Typography
                        variant="h4"
                        color="textPrimary"
                        style={{marginTop: '-40px'}}
                    >
                        Ops, parece que você ainda não criou um documento
                    </Typography>
                    <Typography
                        variant="h6"
                        color="textSecondary"
                        style={{marginTop: '10px'}}
                    >
                        Aqui você pode ver a listagem, status e criar novos
                        documentos.
                    </Typography>
                    <Button
                        style={{
                            marginTop: '20px',
                            color: '#fff',
                        }}
                        variant="contained"
                        onClick={handleOpenModal}
                    >
                        Criar novo Documento
                    </Button>
                </div>
            ) : (
                <Container>
                    <Header>
                        Aqui você pode ver a listagem, status e criar novos
                        documentos.
                    </Header>
                    <Grid2
                        style={{overflow: 'auto'}}
                        container
                        rowSpacing={2}
                        columnSpacing={3}
                    >
                        <CardContainer
                            style={{
                                backgroundColor: '#8DBADF2B',
                                color: 'black',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                cursor: 'pointer',
                            }}
                            onClick={handleOpenModal}
                        >
                            <AddCircleOutlineIcon
                                style={{color: '#012441', marginBottom: '8px'}}
                            />
                            <span
                                style={{
                                    width: '82px',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    color: '#012441',
                                }}
                            >
                                Criar novo documento
                            </span>
                        </CardContainer>
                        {projectArray.map((project) => (
                            <Grid2 key={project.id} size="auto">
                                <CardContainer>
                                    <CardHead>
                                        <Tooltip title={project.name}>
                                            <Typography
                                                variant="h6"
                                                align="center"
                                                fontWeight="bold"
                                                sx={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    maxWidth: '180px',
                                                }}
                                            >
                                                {project.name}
                                            </Typography>
                                        </Tooltip>
                                        <div style={{marginLeft: 'auto'}}>
                                            <Button
                                                id="fade-button"
                                                aria-controls={
                                                    open
                                                        ? 'fade-menu'
                                                        : undefined
                                                }
                                                aria-haspopup="true"
                                                aria-expanded={
                                                    open ? 'true' : undefined
                                                }
                                                onClick={(event) =>
                                                    handleClick(
                                                        event,
                                                        project.id
                                                    )
                                                }
                                            >
                                                <MoreVertIcon />
                                            </Button>
                                            <Menu
                                                id="fade-menu"
                                                MenuListProps={{
                                                    'aria-labelledby':
                                                        'long-button',
                                                }}
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                TransitionComponent={Fade}
                                                PaperProps={{
                                                    style: {
                                                        boxShadow: 'none',
                                                        backgroundColor:
                                                            '#f5f5f5',
                                                    },
                                                }}
                                            >
                                                <MenuItem
                                                    onClick={() => {
                                                        handleClose()
                                                        archiveProject_(
                                                            project.id
                                                        )
                                                    }}
                                                >
                                                    Arquivar{' '}
                                                    <ArchiveOutlinedIcon />
                                                </MenuItem>
                                            </Menu>
                                        </div>
                                    </CardHead>
                                    <CardBody
                                        onClick={() => {
                                            handleDownload(project)
                                        }}
                                    >
                                        <Tooltip title={project.updated_at}>
                                            <TruncatedTypography
                                                style={{fontSize: '13px'}}
                                                variant="caption"
                                                color="textSecondary"
                                            >
                                                Atualizado:{' '}
                                                {project.updated_at
                                                    ? formatDate(
                                                          project.updated_at
                                                      )
                                                    : 'Data indisponivel'}
                                            </TruncatedTypography>
                                        </Tooltip>
                                        <Tooltip title={project.description}>
                                            <TruncatedTypography
                                                style={{fontSize: '13px'}}
                                                variant="caption"
                                                color="textSecondary"
                                            >
                                                {project.description}
                                            </TruncatedTypography>
                                        </Tooltip>
                                    </CardBody>
                                    <CardFooter
                                        onClick={() => {
                                            handleDownload(project)
                                        }}
                                    >
                                        {project.status === 'FINISHED' ? (
                                            <>
                                                <Typography
                                                    variant="body2"
                                                    style={{
                                                        color: 'white',
                                                        backgroundColor:
                                                            '#007AFF',
                                                        padding: '6px',
                                                        borderRadius: '100px',
                                                    }}
                                                >
                                                    Pronto
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    style={{
                                                        color: '#1976D2',
                                                        fontSize: '14px',
                                                        marginLeft: 'auto',
                                                        marginRight: '12px',
                                                        cursor: 'pointer',
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <FileDownloadIcon />{' '}
                                                    Download
                                                </Typography>
                                            </>
                                        ) : project.status === 'INCOMPLETE' ? (
                                            <>
                                                <ContentStatus>
                                                    <Typography variant="body2">
                                                        Incompleto
                                                    </Typography>
                                                </ContentStatus>

                                                <Typography
                                                    variant="body2"
                                                    style={{
                                                        color: '#FFA500',
                                                        fontSize: '14px',
                                                        marginLeft: '20px',
                                                        cursor: 'pointer',
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <EditIcon /> Continuar
                                                </Typography>
                                            </>
                                        ) : (
                                            <>
                                                <Typography
                                                    variant="body2"
                                                    style={{
                                                        color: 'white',
                                                        backgroundColor:
                                                            '#EF6C00',
                                                        padding: '6px',
                                                        borderRadius: '100px',
                                                    }}
                                                >
                                                    Pendente
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    style={{
                                                        color: '#EF6C00',
                                                        fontSize: '14px',
                                                        marginLeft: '10px',
                                                        cursor: 'pointer',
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <AutorenewIcon />{' '}
                                                    Processando
                                                </Typography>
                                            </>
                                        )}
                                    </CardFooter>
                                </CardContainer>
                            </Grid2>
                        ))}
                    </Grid2>
                </Container>
            )}

            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                onKeyDown={(event) => {
                    if (
                        event.key === 'Enter' &&
                        selectedDocumentType &&
                        documentName
                    ) {
                        handleConfirm()
                    }
                }}
            >
                <ModalContent>
                    <TextField
                        label="Nome do Documento"
                        variant="outlined"
                        value={documentName}
                        onChange={(e) => setDocumentName(e.target.value)}
                        fullWidth
                        autoFocus
                    />

                    {documentName.length > 0 && documentName.length < 3 && (
                        <span style={{color: 'red'}}>
                            O nome do documento deve ter no mínimo 3 caracteres.
                        </span>
                    )}

                    {documentName.length > 200 && (
                        <span style={{color: 'red'}}>
                            O nome do documento deve ter no máximo 200
                            caracteres.
                        </span>
                    )}
                    <Select
                        value={selectedDocumentType}
                        onChange={(e) =>
                            setSelectedDocumentType(e.target.value)
                        }
                        displayEmpty
                        fullWidth
                    >
                        <MenuItem value="" disabled>
                            Tipo do Documento
                        </MenuItem>
                        <MenuItem value="ETP">ETP</MenuItem>
                    </Select>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: '16px',
                        }}
                    >
                        <Button
                            style={{color: '#012441'}}
                            variant="text"
                            onClick={handleCloseModal}
                        >
                            Cancelar
                        </Button>
                        <Button
                            style={{
                                backgroundColor:
                                    !selectedDocumentType ||
                                    !documentName ||
                                    documentName.length < 3 ||
                                    documentName.length > 200
                                        ? '#b0c4de'
                                        : '#012441',
                                color: '#fff',
                            }}
                            variant="contained"
                            onClick={handleConfirm}
                            disabled={
                                !selectedDocumentType ||
                                !documentName ||
                                documentName.length < 3 ||
                                documentName.length > 200
                            }
                        >
                            Continuar
                        </Button>
                    </div>
                </ModalContent>
            </Modal>
            {openAlertErro && (
                <Alert
                    style={{alignSelf: 'flex-end'}}
                    variant="filled"
                    severity="error"
                    onClose={() => {
                        setOpenAlertErro(false)
                    }}
                >
                    Seus Créditos para gerar documentos acabaram
                </Alert>
            )}
            {_desarquivarModal()}
        </Page>
    )
}
