import styled from 'styled-components'
import {Dispatch, SetStateAction} from 'react'

const StyledHamburgerButton = styled.button<{
    show: boolean
    position: string
    offset: string
}>`
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: fixed;
    top: 15px; // Position at top instead of middle
    ${({position, offset}) => `${position}: ${offset}`};
    z-index: 55555;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 20px;
    padding: 0;
    margin-left: 15px;
`

const HamburgerLine = styled.span<{show: boolean}>`
    display: block;
    height: 3px;
    width: 100%;
    border-radius: 10px;
    background: #666;
    transition: all 0.3s ease-in-out;

    &:nth-child(1) {
        transform: ${({show}) =>
            show ? 'rotate(45deg) translate(5px, 5px)' : 'rotate(0)'};
    }

    &:nth-child(2) {
        opacity: ${({show}) => (show ? '0' : '1')};
    }

    &:nth-child(3) {
        transform: ${({show}) =>
            show ? 'rotate(-45deg) translate(5px, -5px)' : 'rotate(0)'};
    }
`

interface HamburgerButtonProps {
    show: boolean
    setShow: Dispatch<SetStateAction<boolean>>
    offset: string
    position: 'right' | 'left'
}

export default function HamburgerButton({
    show,
    setShow,
    position,
    offset,
}: HamburgerButtonProps) {
    return (
        <div>
            {!show ? (
                <StyledHamburgerButton
                    position={position}
                    offset={offset}
                    onClick={() => setShow((prev) => !prev)}
                    show={show}
                    title={show ? 'Fechar menu' : 'Abrir menu'}
                    aria-label={show ? 'Fechar menu' : 'Abrir menu'}
                >
                    <HamburgerLine show={show} />
                    <HamburgerLine show={show} />
                    <HamburgerLine show={show} />
                </StyledHamburgerButton>
            ) : null}
        </div>
    )
}
